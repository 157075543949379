import { INCIDENT_ALERTS_IMPORT_FAILED, INCIDENT_ALERTS_IMPORT_PROCESSING, INCIDENT_ALERTS_IMPORT_REQUESTED, INCIDENT_ALERTS_IMPORT_SUCCESSED, LOGOUT_USER } from "../../constants/actionTypes";

const initialState = {
  result: {},
  isProcessing: null,
  isSuccess: null,
  hasErrors: null,
};

export const importAlertsStore = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return initialState;
    }
    case INCIDENT_ALERTS_IMPORT_REQUESTED: {
      return {
        ...state,
        result: null,
        error: null,
        loading: true,
        isSuccess:false,
        requested: true,
      };
    }
    case INCIDENT_ALERTS_IMPORT_PROCESSING: {
      return {
        ...state,
        result: null,
        error: null,
        loading: true,
        isSuccess:false,
        requested: false,
      };
    }
    case INCIDENT_ALERTS_IMPORT_SUCCESSED: {
      return {
        result: action.data,
        error: null,
        loading: false,
        isSuccess:true,
        requested: false,
      };
    }
    case INCIDENT_ALERTS_IMPORT_FAILED: {
      return {
        result: null,
        error: action.data,
        loading: false,
        requested: false,
        isSuccess:false,
      };
    }
    // case ASSET_IMPORT_CLEAR_REQUESTED:{
    //   return {
    //     ...state,
    //     isProcessing: null,
    //     isSuccess: null,
    //     hasErrors: null,
    //   };
    // }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default importAlertsStore;